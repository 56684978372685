import {FC} from 'react';
import {Grid} from '@mui/material';
import {CountInfoItem} from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/about/partials/CountInfo/fullScreenContent/CountInfoItem';

interface IProps {
    averageSellingPrice: string;
    numberOfTotalForSale: string;
    averageRentalPrice: string;
    numberOfTotalForRent: string;
}

export const FullScreenContent: FC<IProps> = ({
    averageSellingPrice,
    numberOfTotalForSale,
    averageRentalPrice,
    numberOfTotalForRent,
}) => {
    return <>
        <Grid container spacing={3}>
            <Grid
                item
                xs={3}
            >
                <CountInfoItem
                    title={<>Priemerná<br/> predajná cena</>}
                    price={averageSellingPrice}
                />
            </Grid>
            <Grid
                item
                xs={3}
            >
                <CountInfoItem
                    title={<>Aktuálny počet<br/> na predaj</>}
                    price={numberOfTotalForSale}
                />
            </Grid>
            <Grid
                item
                xs={3}
            >
                <CountInfoItem
                    title={<>Priemerná<br/> cena prenájmu</>}
                    price={averageRentalPrice}
                />
            </Grid>
            <Grid
                item
                xs={3}
            >
                <CountInfoItem
                    title={<>Aktuálny počet<br/> na prenájom</>}
                    price={numberOfTotalForRent}
                    rightBorder={false}
                />
            </Grid>
        </Grid>
    </>;
};
