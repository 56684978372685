import {FC} from 'react';
import {formatPrice} from 'modules/format/price';
import {EUnitPriceName} from 'modules/format/enums/EUnitPriceName';
import {IDetailAdvertisementsCountInfo} from 'modules/realEstate/common/interfaces/Detail/IDetailAdvertisementsCountInfo';
import {FullScreenContent} from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/about/partials/CountInfo/fullScreenContent/FullScreenContent';
import {StandardContent} from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/about/partials/CountInfo/standardContent/StandardContent';

interface ICountInfo {
    countInfo: IDetailAdvertisementsCountInfo;
    fullScreenVersion?: boolean;
}

export const CountInfo: FC<ICountInfo> = ({countInfo, fullScreenVersion}) => {

    const getPrice = (priceValue: number, unitPriceName?: EUnitPriceName): string => {
        return !priceValue
            ? '-/-'
            : formatPrice(priceValue, unitPriceName, 0);
    };

    const averageSellingPrice = getPrice(countInfo.averageSellingPrice);
    const numberOfTotalForSale = countInfo.numberOfTotalForSale.toString();
    const averageRentalPrice = getPrice(countInfo.averageRentalPrice, EUnitPriceName.EUR_MONTH);
    const numberOfTotalForRent = countInfo.numberOfTotalForRent.toString();

    if (fullScreenVersion) {
        return <FullScreenContent
            averageSellingPrice={averageSellingPrice}
            numberOfTotalForSale={numberOfTotalForSale}
            averageRentalPrice={averageRentalPrice}
            numberOfTotalForRent={numberOfTotalForRent}
        />;
    }

    return <StandardContent
        averageSellingPrice={averageSellingPrice}
        numberOfTotalForSale={numberOfTotalForSale}
        averageRentalPrice={averageRentalPrice}
        numberOfTotalForRent={numberOfTotalForRent}
    />;
};
