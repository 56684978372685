import {FC} from 'react';
import {CardWrapper} from 'modules/theme/components/branch/card/partials/cardWrapper/CardWrapper';
import {BranchName} from 'modules/theme/components/branch/card/partials/texts/BranchName';
import {AddressLines} from 'modules/theme/components/branch/card/partials/texts/AddressLines';
import {NumberOfOffers} from 'modules/theme/components/branch/card/partials/texts/NumberOfOffers';
import {DetailLink} from 'modules/theme/components/branch/card/partials/texts/DetailLink';
import Link from 'components/link/Link';

interface IBranchCard {
    branchName: string;
    addressLines: string[];
    numberOfOffers: number;
    branchDetailUrl: string;
}

export const BranchCard: FC<IBranchCard> = ({
    branchName,
    addressLines,
    numberOfOffers,
    branchDetailUrl,
}) => {
    return <>
        <Link
            noUnderline
            href={branchDetailUrl}
        >
            <CardWrapper>
                <BranchName branchName={branchName} />
                <AddressLines addressLines={addressLines} />
                <NumberOfOffers numberOfOffers={numberOfOffers} />
                <DetailLink detailUrl={branchDetailUrl} />
            </CardWrapper>
        </Link>
    </>;
};
