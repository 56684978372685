import {IOpenHours} from 'modules/realEstate/agency/detail/interfaces/agency/partials/openHours/IOpenHours';
import {IOpenHoursDay} from 'modules/realEstate/agency/detail/interfaces/agency/partials/IOpenHoursDay';

export const transformOpenHours = (openHours: IOpenHours): IOpenHoursDay[] => {
    const openHoursDays: IOpenHoursDay[] = [];

    Object.entries(openHours).forEach((openHoursItem) => {
        const oneDay: IOpenHoursDay = {
            day: '',
            from: '',
            to: '',
            nonstop: false,
            closed: false
        };

        const dayName = openHoursItem[0];
        if (dayName === 'note') {
            return;
        }

        switch(dayName) {
        case 'monday':
            oneDay.day = 'Pondelok';
            break;
        case 'tuesday':
            oneDay.day = 'Utorok';
            break;
        case 'wednesday':
            oneDay.day = 'Streda';
            break;
        case 'thursday':
            oneDay.day = 'Štvrtok';
            break;
        case 'friday':
            oneDay.day = 'Piatok';
            break;
        case 'saturday':
            oneDay.day = 'Sobota';
            break;
        case 'sunday':
            oneDay.day = 'Nedeľa';
            break;
        }

        const dayFromTo = openHoursItem[1];
        if (!dayFromTo) {
            oneDay.closed = true;
        }

        if (dayFromTo && dayFromTo.nonstop) {
            oneDay.nonstop = true;
        }

        if (dayFromTo && !dayFromTo.nonstop) {
            oneDay.from = dayFromTo.from || '';
            oneDay.to = dayFromTo.to || '';
            oneDay.nonstop = dayFromTo.nonstop || false;
        }

        openHoursDays.push(oneDay);
    });

    return openHoursDays;
};