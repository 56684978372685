import {FC} from 'react';
import {Box, Theme, Palette} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';

interface IOpenHoursNote {
    note: string;
}

export const OpenHoursNote: FC<IOpenHoursNote> = ({note}) => {

    return <Box
        pr={2}
        pl={2}
        top={10}
        pt={0.5}
        pb={0.5}
        right={10}
        zIndex={1}
        display='flex'
        alignItems='center'
        borderRadius='99px'
        position='absolute'
        bgcolor={(theme: Theme) => theme.palette.labelPrimary.main}
    >
        <Box mr={1}>
            <Box
                width={12}
                height={12}
                borderRadius='50%'
                border={(theme: Theme) => `2px solid ${theme.palette.backgroundPrimary.main}`}
                bgcolor={(theme: Theme) => theme.palette.identityBasicGreen.main}
            />
        </Box>

        <Text
            variant='label2'
            semibold color={(palette: Palette) => palette.backgroundPrimary}
        >
            {note}
        </Text>
    </Box>;

};
