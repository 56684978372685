import {useCallback, useState} from 'react';
import {realEstateAdvertisementDetailListingApi } from 'modules/api/client';
import {LIMIT} from 'components/page/realEstate/common/advertisements/constants/limit';
import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import {
    realEstateAdvertisementListingAdvertisementDtoToIAdvertisementMapper
} from 'components/page/realEstate/common/advertisements/helpers/mappers/realEstateAdvertisementListingAdvertisementDtoToIAdvertisementMapper';
import scrollToTop from 'modules/layout/scrollToTop';
import {useRouter} from 'next/navigation';
import { REAL_ESTATE_BRANCH_DETAIL_TAB } from 'modules/route/routes';
import { ERouterTabs } from 'modules/route/enums/agency/detail/ERouterTabs';
import { ERouterBranchType } from 'modules/route/enums/agency/detail/ERouterBranchType';

export interface IReturn {
    noData: boolean;
    currentPage: number;
    isFetching: boolean;
    advertisements: IAdvertisement[];
    fetchNext: (page: number) => void;
}

export const useAdvertisements = (
    agencyId: string,
    firstAdvertisements: IAdvertisement[],
    preloadedPage: number,
    agencySlugName: string,
    branchType: string,
): IReturn => {
    const router = useRouter();
    const [noData, setNoData] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(preloadedPage);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [advertisements, setAdvertisements] = useState<IAdvertisement[]>(firstAdvertisements);

    const hasNoData = advertisements?.length === 0 || noData;

    const fetchAdvertisementsApiCall = useCallback(async (page: number) => {
        setCurrentPage(page);

        router.replace(REAL_ESTATE_BRANCH_DETAIL_TAB(agencyId, agencySlugName, branchType as ERouterBranchType, ERouterTabs.ADVERTISEMENT, page));

        if (page === preloadedPage) {
            setAdvertisements(firstAdvertisements);
            scrollToTop();
            return;
        }

        const skip = (page * LIMIT) - LIMIT;

        try {
            setIsFetching(true);
            const { data } = await realEstateAdvertisementDetailListingApi.getAdvertisementsListForAgencyDetail(agencyId, skip === 0 ? undefined : skip, LIMIT);
            const {
                isAgencyProfi,
                advertisements: responseAdvertisements,
                numberOfTotalAdvertisements: responseNumberOfTotalAdvertisements
            } = data;

            const advertisementsFormatted: IAdvertisement[] = responseAdvertisements
                .map(responseAdv => realEstateAdvertisementListingAdvertisementDtoToIAdvertisementMapper(isAgencyProfi, responseAdv));

            setAdvertisements(advertisementsFormatted);
            const hasNoData = !responseNumberOfTotalAdvertisements || !advertisementsFormatted || advertisementsFormatted.length === 0;
            setNoData(hasNoData);

            if (!hasNoData) {
                scrollToTop();
            }
        } catch (error) {
            setNoData(true);
        } finally {
            setIsFetching(false);
        }
    }, [agencyId, router, firstAdvertisements, preloadedPage, agencySlugName, branchType]);

    const fetchNext = useCallback((page: number) => {
        fetchAdvertisementsApiCall(page);
    }, [fetchAdvertisementsApiCall]);

    return {
        noData: hasNoData,
        fetchNext,
        isFetching,
        currentPage,
        advertisements,
    };
};
