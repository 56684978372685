import {FC, ReactNode} from 'react';
import {Box} from '@mui/material';

interface IChartWrapper {
    children: ReactNode;
}

export const ChartWrapper: FC<IChartWrapper> = ({children}) => {

    return (
        <Box 
            display={'flex'}
            alignItems={'center'}
            minHeight={360} 
            width={'100%'} 
            borderRadius={4} 
            border={(theme) => `1px solid ${theme.palette.dividerTransparent}`}
            p={(theme) => ({xs: theme.spacing(0,2), md: theme.spacing(0, 6)})}
        >
            <Box
                width={'100%'}
            >
                {children}
            </Box>
        </Box>

    );
};