import {FC} from 'react';
import {Box, Theme} from '@mui/material';
import Image from 'components/image/Image';
import placeholder from 'modules/theme/components/agency/common/svg/placeholderAgentLogo.svg?url';

interface IAgencyLogo {
    imgUrl: string;
    alt?: string;
    title?: string;
}

export const AgencyLogoMobile: FC<IAgencyLogo> = ({imgUrl, alt, title}) => {
    return <Box
        width={64}
        height={64}
        padding={1}
        borderRadius={1}
        border="1px solid"
        position="relative"
        borderColor={(theme: Theme) => theme.palette.dividerTransparent}
    >
        <Box position={'relative'} width={'100%'} height={'100%'}>
            <Image
                fill
                alt={alt || 'Logo'}
                title={title}
                src={imgUrl || placeholder}
                style={{
                    objectFit: imgUrl ? 'contain' : 'cover'
                }}
            />
        </Box>
    </Box>;
};
