import { IAgent } from 'modules/realEstate/agency/detail/interfaces/IAgent';
import { RealEstateAgencyDetailAgentDto } from 'modules/api/generated';

export const mapAgencyDetailAgentDtoToAgentInterfaceMapper = (dto: RealEstateAgencyDetailAgentDto[]): IAgent[] => {

    return dto.map(newAgent => ({
        id: newAgent.userId,
        isPro: newAgent.isPro || false,
        agentName: newAgent.userName,
        agentSlugName: newAgent.userSlugName,
        agentPreviewPhotoUrl: newAgent.userPreviewPhotoUrl || null,
    }));
};