import {FC} from 'react';
import {Box, useTheme} from '@mui/material';
import {Svg} from 'modules/theme/components/svg/Svg';
import BadgeProfiClientMobile from 'modules/theme/components/agency/agency/common/svg/BudgeProfiClientMobile.svg';
import {Text} from 'modules/theme/components/text/Text';

export const ProfiBadge: FC = () => {
    const {palette} = useTheme();

    return <Box
        display={'flex'}
        alignItems="center"
        borderRadius={8}
        gap={1}
        p={0.5}
        sx={{
            backgroundColor: palette.backgroundSecondary.main,
        }}
    >
        <Svg width={32} height={32} component={BadgeProfiClientMobile}/>
        <Text bold variant={'label2'}>Profesionálny člen</Text>
        <Box width={10} />
    </Box>;
};
