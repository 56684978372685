import {
    IOpenHours
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/openHours/IOpenHours';

interface IReturn {
    text: string;
    isOpen: boolean;
}

export const useOpenHours = (openHours: IOpenHours): IReturn => {
    const now = new Date();
    const day = now.getDay();
    // const hours = now.getHours();
    // const minutes = now.getMinutes();
    const openHoursTimeFrom = (new Date());
    const openHoursTimeTo = (new Date());

    let text = 'Dnes otvorené NONSTOP';
    let isOpen = false;

    // NOTE: in case openHours are missing.
    if (!openHours) return {
        text,
        isOpen
    };

    const {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
    } = openHours;

    const checkIsOpen = (openFrom: string, openTo: string): boolean => {
        const [ openHour, openMinute ] = openFrom.split(':');
        const [ closeHour, closeMinute ] = openTo.split(':');
        openHoursTimeFrom.setHours(+openHour);
        openHoursTimeFrom.setMinutes(+openMinute);
        openHoursTimeTo.setHours(+closeHour);
        openHoursTimeTo.setMinutes(+closeMinute);

        return openHoursTimeFrom < now && now < openHoursTimeTo;
    };

    if (day === 0 && sunday) {
        if (sunday.nonstop) {
            isOpen = true;
            text = 'Nonstop';
        } else if (sunday.from && sunday.to) {
            isOpen = checkIsOpen(sunday.from, sunday.to);
            text = `Otvorené do ${sunday.to} hod`;
        }
    }

    if (day === 1 && monday) {
        if (monday.nonstop) {
            isOpen = true;
            text = 'Nonstop';
        } else if (monday.from && monday.to) {
            isOpen = checkIsOpen(monday.from, monday.to);
            text = `Otvorené do ${monday.to} hod`;
        }
    }

    if (day === 2 && tuesday) {
        if (tuesday.nonstop) {
            isOpen = true;
        } else if (tuesday.from && tuesday.to) {
            isOpen = checkIsOpen(tuesday.from, tuesday.to);
            text = `Otvorené do ${tuesday.to} hod`;
        }
    }

    if (day === 3 && wednesday) {
        if (wednesday.nonstop) {
            isOpen = true;
        } else if (wednesday.from && wednesday.to) {
            isOpen = checkIsOpen(wednesday.from, wednesday.to);
            text = `Otvorené do ${wednesday.to} hod`;
        }
    }

    if (day === 4 && thursday) {
        if (thursday.nonstop) {
            isOpen = true;
        } else if (thursday.from && thursday.to) {
            isOpen = checkIsOpen(thursday.from, thursday.to);
            text = `Otvorené do ${thursday.to} hod`;
        }
    }

    if (day === 5 && friday) {
        if (friday.nonstop) {
            isOpen = true;
        } else if (friday.from && friday.to) {
            isOpen = checkIsOpen(friday.from, friday.to);
            text = `Otvorené do ${friday.to} hod`;
        }
    }

    if (day === 6 && saturday) {
        if (saturday.nonstop) {
            isOpen = true;
        } else if (saturday.from && saturday.to) {
            isOpen = checkIsOpen(saturday.from, saturday.to);
        }
    }

    return {
        text,
        isOpen,
    };
};
