import React, {FC} from 'react';
import { Box } from '@mui/material';
import { Button } from 'modules/theme/components/button/Button';
import { IconBadge } from 'components/page/realEstate/common/contacts/IconBadge';
import { useHeadlineContacts } from 'components/page/realEstate/common/hooks/useHeadlineContacts';
import EnvelopeIcon from 'modules/theme/components/icons/basic/Envelope.svg';
import WebIcon from 'modules/theme/components/icons/basic/Web.svg';
import MessengerIcon from 'modules/theme/components/icons/social/Messenger.svg';
import WhatsappIcon from 'modules/theme/components/icons/social/Whatsapp.svg';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';

interface IContactsProps {
    agencyId: string;
    agencyName: string;
    email?: string;
    web?: string;
    whatsapp?: string;
    messenger?: string;
    parentAgencyName?: string;
}
export const Contacts: FC<IContactsProps> = ({
    agencyId,
    agencyName,
    email,
    web,
    messenger,
    whatsapp,
    parentAgencyName,
}) => {
    const {
        phoneNumbers,
        onMainPhoneButtonClick,
        onMailContactClick,
        onMessengerContactClick,
        onWhatsappContactClick,
        onWebClick,
        components,
        isFetchingPhoneNumber,
    } = useHeadlineContacts(agencyId, agencyName, false, parentAgencyName);

    const { facebookPixel: { sendEvent: sendFacebookPixelEvent } } = useAnalytics();

    return <>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1}>
            <Box width={280}>
                <Button
                    disabled={isFetchingPhoneNumber}
                    href={phoneNumbers?.main ? `tel: ${phoneNumbers.main}` : undefined}
                    text={phoneNumbers?.main || '+421... Zobraziť číslo'}
                    onClick={() => onMainPhoneButtonClick(agencyId)}
                    fullWidth
                />
            </Box>
            {email && <IconBadge component={EnvelopeIcon} onClick={onMailContactClick}/>}
            {web && <IconBadge component={WebIcon} href={web} rels={['sponsored']} onClick={() => onWebClick(web)} />}
            {messenger && <IconBadge
                component={MessengerIcon}
                onClick={() => {
                    onMessengerContactClick(messenger);
                    sendFacebookPixelEvent(['track', 'Contact']);
                }}
            />}
            {whatsapp && <IconBadge
                component={WhatsappIcon}
                onClick={() => {
                    onWhatsappContactClick(whatsapp);
                }}
            />}
        </Box>
        {components}
    </>;
};
