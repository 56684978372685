import {useState, useEffect} from 'react';
import {DESCRIPTION_MAX_LENGTH} from 'components/page/realEstate/agency/detail/constants/about/description';

interface IReturn {
    isButtonVisible: boolean;
    collapseText: () => void;
    text: string;
}

export const useDescriptionCollapse = (descriptionFull: string): IReturn => {
    const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false);
    const [text, setText] = useState<string>(descriptionFull || '');

    useEffect(() => {
        if (descriptionFull && descriptionFull.length > DESCRIPTION_MAX_LENGTH) {
            const description = descriptionFull.trim().slice(0, DESCRIPTION_MAX_LENGTH) + '...';

            setText(description);
            setIsButtonVisible(true);
        }
    }, [descriptionFull]);

    const collapseText = (): void => {
        setText(descriptionFull);
        setIsButtonVisible(false);
    };

    return {
        isButtonVisible,
        collapseText,
        text
    };
};