import {FC} from 'react';
import {IContacts} from 'modules/realEstate/common/interfaces/IContacts';
import {
    ProfiBadge
} from 'components/page/realEstate/agency/detail/components/partials/headline/desktop/partials/ProfiBadge';
import {Grid} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';
import {AgencyLogo} from 'components/page/realEstate/agency/detail/components/partials/headline/desktop/partials/AgencyLogo';
import {IAddress} from 'modules/realEstate/common/interfaces/IAddress';
import {Contacts} from 'components/page/realEstate/agency/detail/components/partials/headline/desktop/partials/Contacts';
import {Address} from 'components/page/realEstate/agency/detail/components/partials/headline/common/Address';
import { getLocationString } from 'modules/theme/components/agency/agency/listingCard/helpers/getLocationString';
import { ERouterBranchType } from 'modules/route/enums/agency/detail/ERouterBranchType';

interface IProps {
    isProfi: boolean;
    agencyId: string;
    agencyName: string;
    agencyLogoUrl: string;
    agencyAddress: IAddress;
    contacts: IContacts;
    branchType: ERouterBranchType;
    parentAgencyName?: string;
}

export const HeadlineDesktop: FC<IProps> = ({
    isProfi,
    agencyId,
    agencyName,
    agencyLogoUrl ,
    agencyAddress,
    contacts,
    branchType,
    parentAgencyName,
}) => {
    const location = getLocationString(agencyAddress?.city, agencyAddress?.street, agencyAddress?.region, agencyAddress?.streetNumber);

    const alt = [...agencyName.split(' '), 'logo', branchType === ERouterBranchType.BRANCH ? 'pobocky' : undefined, 'realitna', 'kancelaria']
        .filter(value => !!value).join('_');

    return <Grid container alignItems={'flex-start'} justifyContent={'space-between'}>
        <Grid item xs={6}>
            <Grid container item mb={2.5}>
                <Grid container item mb={1}>
                    <Text variant={'h2'} component="h1">
                        {agencyName}
                    </Text>
                </Grid>
                {location && (
                    <Grid container item mb={1}>
                        <Address
                            location={location}
                            variant={'body2'}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container item>
                <Contacts
                    agencyId={agencyId}
                    agencyName={agencyName}
                    email={contacts.email}
                    web={contacts.web}
                    messenger={contacts.messenger}
                    whatsapp={contacts.whatsapp}
                    parentAgencyName={parentAgencyName}
                />
            </Grid>
        </Grid>
        <Grid item>
            <Grid container item justifyContent={'flex-end'}>
                <Grid item>
                    <Grid container justifyContent={'flex-end'}>
                        <Grid item>
                            <AgencyLogo imgUrl={agencyLogoUrl} alt={alt} title={alt} />
                        </Grid>
                    </Grid>
                    {isProfi && (
                        <Grid container justifyContent={'center'} mt={2}>
                            <Grid item>
                                <ProfiBadge/>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    </Grid>;
};
