import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';
import {Palette} from '@mui/material';
import Link from 'components/link/Link';

interface IDetailLink {
    detailUrl: string;
}

export const DetailLink: FC<IDetailLink> = ({
    detailUrl,
}) => {
    return (
        <Link href={detailUrl}>
            <Text
                variant="label1"
                underLine
                color={(palette: Palette) => palette.labelPrimary}
                noWrap
            >
                Detail pobočky
            </Text>
        </Link>
    );
};
