import {FC, ReactNode} from 'react';
import {Box} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';

interface ICountInfoItem {
    title: ReactNode;
    price: string;
}

export const CountInfoItem: FC<ICountInfoItem> = ({title, price}) => {

    return <Box
        pl={3}
        pt={3}
        pb={3}
    >
        <Text variant='body2' mobileVariant='label1' mb={1}>
            {title}
        </Text>
        <Text variant='h4' mobileVariant='h5' bold>
            {price}
        </Text>
    </Box>;
};