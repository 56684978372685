import {FC} from 'react';
import { Box, Grid } from '@mui/material';
import {ILocationGps} from 'modules/realEstate/agency/detail/interfaces/agency/partials/ILocationGps';
import {IOpenHours} from 'modules/realEstate/agency/detail/interfaces/agency/partials/openHours/IOpenHours';
import {Subtitle} from 'components/page/realEstate/agency/detail/components/tabs/content/common/subtitle/Subtitle';
import {
    OpenHours
} from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/whereWeAre/partials/openHours/OpenHours';
import {
    LocationMap
} from 'components/page/realEstate/agency/detail/components/partials/locationMap/LocationMap';

interface IWhereWeAre {
    locationGps: ILocationGps;
    openHours: IOpenHours;
}

export const WhereWeAre: FC<IWhereWeAre> = ({locationGps, openHours}) => {
    return <>
        {locationGps && 
            <Box mb={3}>
                <Subtitle subtitle="Kde nás nájdete"/>
            </Box>
        }
        <Grid
            container
            spacing={2}
            flexDirection={{xs: 'column', sm: 'row'}}
        >
            {locationGps && (
                <Grid item xs={12} sm={6} md={8}>
                    <LocationMap
                        height={300}
                        locationGps={locationGps}
                    />
                </Grid>
            )}
            {openHours && (
                <Grid item xs={12} sm={6} md={4}>
                    <OpenHours openHours={openHours}/>
                </Grid>
            )}

            {/* TODO: will be added later.
            <Grid item xs={12} sm={6} md={4}>
                {openHours ? (
                    <OpenHours openHours={openHours}/>
                ) : (
                    <NoOpenHours />
                )}
                    
            </Grid> */}
        </Grid>
    </>;
};