import {FC} from 'react';
import {Palette} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';
import {lemmatizeWordAccordingToNumber} from 'modules/lemmatize/lemmatizeWordAccordingToNumber';
import {ILemmatizeWordAccordingToNumber} from 'modules/lemmatize/ILemmatizeWordAccordingToNumber';

interface INumberOfOffers {
    numberOfOffers: number;
}

export const NumberOfOffers: FC<INumberOfOffers> = ({
    numberOfOffers
}) => {

    const lemmatizeWords: ILemmatizeWordAccordingToNumber = {
        eqZero: 'bez ponuky',
        eqOne: 'ponuka',
        betweenTwoAndFourInclusive: 'ponuky',
        moreThenFour: 'ponúk',
        withoutNumberIfZero: true,
    };

    const totalCountTerm = lemmatizeWordAccordingToNumber(numberOfOffers, lemmatizeWords);

    return (
        <Text
            variant="label1"
            mobileVariant="label1"
            semibold
            color={(palette: Palette) => palette.labelPrimary}
            noWrap
            testId="numberOfOffers"
        >
            {totalCountTerm}
        </Text>
    );
}; 
