import {FC} from 'react';
import {IAddress} from 'modules/realEstate/common/interfaces/IAddress';
import {IContacts} from 'modules/realEstate/common/interfaces/IContacts';
import {HeadlineDesktop} from 'components/page/realEstate/agency/detail/components/partials/headline/desktop/HeadlineDesktop';
import {HeadlineMobile} from 'components/page/realEstate/agency/detail/components/partials/headline/mobile/HeadlineMobile';
import {
    ILocationGps
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/ILocationGps';
import {
    IOpenHours
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/openHours/IOpenHours';
import { ERouterBranchType } from 'modules/route/enums/agency/detail/ERouterBranchType';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

export interface IHeadlineProps {
    isProfi: boolean;
    agencyId: string;
    agencyName: string;
    agencyLogoUrl: string;
    agencyAddress: IAddress;
    locationGps: ILocationGps;
    contacts: IContacts;
    openHours?: IOpenHours;
    branchType: ERouterBranchType;
    parentAgencyName?: string;
}
export const Headline: FC<IHeadlineProps> = ({
    isProfi,
    agencyId,
    agencyName,
    agencyLogoUrl ,
    agencyAddress,
    locationGps,
    contacts,
    openHours,
    branchType,
    parentAgencyName,
}) => {

    return <>
        <Mobile>
            <HeadlineMobile
                agencyId={agencyId}
                isProfi={isProfi}
                agencyName={agencyName}
                agencyLogoUrl={agencyLogoUrl}
                agencyAddress={agencyAddress}
                locationGps={locationGps}
                contacts={contacts}
                openHours={openHours}
                branchType={branchType}
                parentAgencyName={parentAgencyName}
            />            
        </Mobile>
        <Desktop>
            <HeadlineDesktop
                agencyId={agencyId}
                isProfi={isProfi}
                agencyName={agencyName}
                agencyLogoUrl={agencyLogoUrl}
                agencyAddress={agencyAddress}
                contacts={contacts}
                branchType={branchType}
                parentAgencyName={parentAgencyName}
            />
        </Desktop>
    </>;
};
