import {FC, ReactNode} from 'react';
import Link from 'components/link/Link';
import {Box} from '@mui/material';

interface IAgencyDetailLinkWrapper {
    children: ReactNode;
    detailUrl: string;
    onClick?: () => void;
}

export const AgentDetailLinkWrapper: FC<IAgencyDetailLinkWrapper> = ({
    children,
    detailUrl,
    onClick,
}) => {
    return (
        <Box component="span" data-test-id="agentDetailLinkWrapper">
            <Link
                noUnderline
                href={detailUrl}
                onClick={onClick}
            >
                {children}
            </Link>
        </Box>
    );
};
