import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';
import {Stack, Palette} from '@mui/material';

interface IAddressLines {
    addressLines: string[];
}

export const AddressLines: FC<IAddressLines> = ({
    addressLines,
}) => {

    const renderLine = (lineNumber: number) => {
        const lineText = addressLines[lineNumber-1] || <>&nbsp;</>;
        return (
            <Text
                variant="label1"
                color={(palette: Palette) => palette.labelSecondary}
                noWrap
            >
                {lineText}
            </Text>
        );
    };

    return (
        <Stack direction='column' data-test-id='addressLines'>
            {renderLine(1)}
            {renderLine(2)}
            {renderLine(3)}
        </Stack>
    );
};
