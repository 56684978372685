import {FC} from 'react';
import {Grid} from '@mui/material';
import {AgentCard} from 'modules/theme/components/agency/agent/card/AgentCard';
import {IAgent} from 'modules/realEstate/agency/detail/interfaces/IAgent';
import {Skeleton} from 'modules/theme/components/agency/agent/card/partials/skeleton/Skeleton';
import {AppConfigService} from 'modules/appConfig/AppConfigService';
import {REAL_ESTATE_AGENT_DETAIL_TAB} from 'modules/route/routes';
import {ERouterTabs} from 'modules/route/enums/agent/detail/ERouterTabs';

interface IAgentsList {
    agents: IAgent[],
    isFetching: boolean,
    nextFetchAgentsCount: number,
}

export const AgentsList: FC<IAgentsList> = ({agents, isFetching, nextFetchAgentsCount}) => {
    const agentsList = agents.map((agent, index) => {
        const agentDetailUrl =
            `${AppConfigService.getNehnutelnostiUrl()}${REAL_ESTATE_AGENT_DETAIL_TAB(agent.id, agent.agentSlugName, ERouterTabs.ADVERTISEMENT)}`;

        return <AgentCard
            isPro={agent.isPro}
            detailUrl={agentDetailUrl}
            agentName={agent.agentName}
            key={index + agent.agentSlugName}
            agentPreviewPhotoUrl={agent.agentPreviewPhotoUrl}
        />;
    });

    if (isFetching) {
        for (let i = 0; i < nextFetchAgentsCount; i++) agentsList.push(<Skeleton key={i}/>);
    }

    return <Grid container alignItems="center" spacing={{xs: 1, md: 2}}>
        {agentsList.map((agent, index) =>
            <Grid item xs={6} md={3} key={index}>
                {agent}
            </Grid>)
        }
    </Grid>;
};
