import { FC, ComponentType } from 'react';
import dynamic from 'next/dynamic';
import { IProps } from 'components/map/Map';

const Map: ComponentType<IProps> = dynamic<IProps>(
    () => import('components/map/Map'),
    { ssr: false }
);

const MapWithoutSSR: FC<IProps> = (props) => {
    return <Map {...props} />;
};

export default MapWithoutSSR;