import {FC} from 'react';
import {Box} from '@mui/material';
import {
    IOpenHoursDay as IOpenHoursDayProps
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/IOpenHoursDay';
import {Text} from 'modules/theme/components/text/Text';

interface IOpenHoursDay {
    openHoursDay: IOpenHoursDayProps;
}

export const OpenHoursDay: FC<IOpenHoursDay> = ({openHoursDay}) => {
    const isNonstop = openHoursDay.nonstop === true;
    const isClosed = openHoursDay.closed === true;

    const openHoursText = isNonstop
        ? 'nonstop'
        : isClosed
            ? 'zatvorené'
            : `${openHoursDay.from} - ${openHoursDay.to}`;

    return (
        <Box
            mb={1}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
        >
            <Text variant='body2'>
                {openHoursDay.day}
            </Text>
            <Text variant='body2' semibold>
                {openHoursText}
            </Text>
        </Box>
    );
};