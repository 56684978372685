export const formatDecimal = (
    priceValue: number,
    maxDigits?: number
): string => {
    const formatter = new Intl.NumberFormat('sk-SK', {
        style: 'decimal',
        maximumFractionDigits: maxDigits
    });

    return formatter.format(priceValue);
};
