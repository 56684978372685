import {IAddress} from 'modules/realEstate/common/interfaces/IAddress';

export const getAgencyAddressMobileFormatted = (address: IAddress): string => {
    const {city, region} = address;

    let addressString = '';

    if (city) {
        addressString = `${city}`;
    }

    if (region) {
        addressString = `${addressString} (okres ${region})`;
    }

    return addressString;
};
