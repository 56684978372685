'use client';

import { FC } from 'react';
import { Box, Container } from '@mui/material';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
import { ERouterTabs } from 'modules/route/enums/agency/detail/ERouterTabs';
import { Tabs } from 'components/page/realEstate/agency/detail/components/tabs/Tabs';
import { Breadcrumbs } from 'components/page/realEstate/common/breadcrumbs/Breadcrumbs';
import { IAgencyDetail } from 'modules/realEstate/agency/detail/interfaces/agency/IAgencyDetail';
import { Headline } from 'components/page/realEstate/agency/detail/components/partials/headline/Headline';
import { ERouterBranchType } from 'modules/route/enums/agency/detail/ERouterBranchType';
import { IBranch } from 'modules/realEstate/agency/detail/interfaces/IBranch';
import { IAgent } from 'modules/realEstate/agency/detail/interfaces/IAgent';
import { IAdvertisement } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import useBreadcrumbs from 'modules/state/page/realEstate/useBreadcrumbs';

interface IProps {
    agency: IAgencyDetail;
    firstAgents: IAgent[];
    preloadedPage: number;
    currentTab: ERouterTabs;
    firstBranches: IBranch[];
    breadcrumbs: IBreadcrumb[];
    numberOfTotalAgents: number;
    branchType: ERouterBranchType;
    numberOfTotalBranches: number;
    firstAdvertisements: IAdvertisement[];
}

export const Main: FC<IProps> = ({
    agency,
    currentTab,
    branchType,
    breadcrumbs,
    firstAgents,
    firstBranches,
    preloadedPage,
    numberOfTotalAgents,
    firstAdvertisements,
    numberOfTotalBranches,
}) => {
    useBreadcrumbs(breadcrumbs);

    return (
        <>
            <Container sx={{ pb: 2 }}>
                <Box mb={3}>
                    <Breadcrumbs />
                </Box>
                <Box mb={2}>
                    <Headline
                        agencyId={agency.agencyId}
                        isProfi={agency.isProfi}
                        agencyName={agency.agencyName}
                        agencyLogoUrl={agency.agencyLogoUrl}
                        agencyAddress={agency.agencyAddress}
                        locationGps={agency.locationGps}
                        contacts={agency.contacts}
                        openHours={agency.openHours}
                        branchType={branchType}
                        parentAgencyName={agency.parentAgency?.agencyName}
                    />
                </Box>
            </Container>
            <Box mb={2}>
                <Tabs
                    agency={agency}
                    currentTab={currentTab}
                    firstBranches={firstBranches}
                    numberOfTotalBranches={numberOfTotalBranches}
                    firstAgents={firstAgents}
                    numberOfTotalAgents={numberOfTotalAgents}
                    firstAdvertisements={firstAdvertisements}
                    preloadedPage={preloadedPage}
                />
            </Box>
        </>
    );
};
