export const getLocationString = (
    city?: string,
    street?: string,
    region?: string,
    streetNumber?: string,
): string => {
    if (!street && !city && !region) return '';
    let location = [`${street ? street : ''} ${streetNumber ? streetNumber : ''}`, city]
        .filter((location) => location)
        .join(', ');

    location = region ? location + ` (${region})` : location;

    return location;
};
