import React, {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';
import MappinIcon from 'modules/theme/components/icons/basic/Mappin.svg';
import {Box} from '@mui/material';
import {Svg} from 'modules/theme/components/svg/Svg';
import {OverridableStringUnion} from '@mui/types';
import {Variant} from '@mui/material/styles/createTypography';
import {TypographyPropsVariantOverrides} from '@mui/material/Typography/Typography';

interface IAddressProps {
    location: string;
    variant: OverridableStringUnion<Variant, TypographyPropsVariantOverrides>;
}
export const Address: FC<IAddressProps> = ({location, variant}) => {
    return (
        <Box display={'flex'} alignContent={'center'} alignItems={'center'} gap={1}>
            <Svg icon component={MappinIcon} width={16} height={16}/>
            <Text variant={variant}>
                {location}
            </Text>
        </Box>
    );
};
