import {FC} from 'react';
import {Box, useTheme} from '@mui/material';
import {Svg} from 'modules/theme/components/svg/Svg';
import BudgeProfiClientDesktop from 'modules/theme/components/agency/agency/common/svg/BudgeProfiClientDesktop.svg';

export const ProfiBadge: FC = () => {
    const {palette} = useTheme();

    return <Box
        display={'flex'}
        alignItems="center"
        borderRadius={8}
        gap={1}
        p={0.5}
        sx={{
            backgroundColor: palette.backgroundSecondary.main,
        }}
    >
        <Svg width={48} height={48} component={BudgeProfiClientDesktop}/>
    </Box>;
};
