import { FC } from 'react';
import {Grid, Box} from '@mui/material';
import { Subtitle } from 'components/page/realEstate/agency/detail/components/tabs/content/common/subtitle/Subtitle';
import {BranchCard} from 'modules/theme/components/branch/card/BranchCard';
import { IParentAgency } from 'modules/realEstate/agency/detail/interfaces/agency/partials/IParentAgency';
import { formatAddressLines } from 'components/page/realEstate/agency/detail/helpers/address/formatAddressLines';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { REAL_ESTATE_BRANCH_DETAIL_TAB } from 'modules/route/routes';
import { ERouterTabs } from 'modules/route/enums/agency/detail/ERouterTabs';
import { getBranchType } from 'components/page/realEstate/common/helpers/AgencyHelper';
interface IPropsParentAgency {
    parentAgency: IParentAgency;
}

export const ParentAgency: FC<IPropsParentAgency> = ({ parentAgency }) => {
    return (
        <>
            <Box mb={3}>
                <Subtitle subtitle="Profil realitnej kancelárie"/>
            </Box>
            <Grid container spacing={{xs: 1, md: 2}}>
                <Grid item xs={12} sm={6} md={4}>
                    <BranchCard
                        branchName={parentAgency.agencyName}
                        addressLines={formatAddressLines(parentAgency.agencyAddress)}
                        branchDetailUrl={`${AppConfigService.getNehnutelnostiUrl()}${REAL_ESTATE_BRANCH_DETAIL_TAB(parentAgency.agencyId, parentAgency.agencySlug, getBranchType(true), ERouterTabs.ABOUT)}`}
                        numberOfOffers={parentAgency.numberOfTotalActiveAdvertisements}
                    />
                </Grid>
            </Grid>
        </>
    );
};