import {FC} from 'react';
import {Divider, Grid} from '@mui/material';
import {CountInfoItem} from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/about/partials/CountInfo/standardContent/CountInfoItem';

interface IProps {
    averageSellingPrice: string;
    numberOfTotalForSale: string;
    averageRentalPrice: string;
    numberOfTotalForRent: string;
}

export const StandardContent: FC<IProps> = ({
    averageSellingPrice,
    numberOfTotalForSale,
    averageRentalPrice,
    numberOfTotalForRent,
}) => {
    return <>
        <Grid container spacing={0}>
            <Grid
                item
                xs={5}
                container
            >
                <CountInfoItem
                    title={<>Priemerná<br/> predajná cena</>}
                    price={averageSellingPrice}
                />
            </Grid>
            <Grid item xs={1}>
                <Divider orientation='vertical'/>

            </Grid>
            <Grid
                item
                xs={5}
                container
            >
                <CountInfoItem
                    title={<>Aktuálny počet<br/> na predaj</>}
                    price={numberOfTotalForSale}
                />
            </Grid>
        </Grid>
        <Divider orientation='horizontal'/>
        <Grid container spacing={0}>
            <Grid
                item
                xs={5}
                container
                flexDirection='column'
            >
                <CountInfoItem
                    title={<>Priemerná<br/> cena prenájmu</>}
                    price={averageRentalPrice}
                />
            </Grid>
            <Grid item xs={1}>
                <Divider orientation='vertical'/>
            </Grid>
            <Grid
                item
                xs={5}
                container
                flexDirection='column'
            >
                <CountInfoItem
                    title={<>Aktuálny počet<br/> na prenájom</>}
                    price={numberOfTotalForRent}
                />
            </Grid>
        </Grid>
    </>;
};
